import * as React from "react"

import {Product} from "../types/Types"
import {useState} from "react"
import {
    Accordion,
    AccordionSummary,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@material-ui/core"
import Image from "./Image"
import {BreadLink, Capitalize} from "./partials"

type Props = {
    product: Product
}

const ExtraInfo = ({product}: Props) => {
    const [expanded, setExpanded] = useState("none")

    const extras = [product.model.metadata.bullet_points?.split("\n"), product.model.metadata.specs?.url, product.model.metadata.catalogurl];
    const extrasTitles = ["Features", "Specs", "Catalog"];

    console.log(extras);
    const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }
    return (
        (extras.filter(val => val != null).length > 0) && (
            <div>
                {extras.map((extra, index) => (
                    extra != null && (
                        <Accordion
                            expanded={expanded === `panel${index}`}
                            onChange={handleChange(`panel${index}`)}
                        >
                            <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                            >
                                <Typography>{Capitalize(extrasTitles[index])}</Typography>
                            </AccordionSummary>
                            {index == 0 && extra != null && (
                                <List dense={true}>
                                    {(extra as string[]).map(content => (
                                        <ListItem>
                                            <ListItemText primary={content}/>
                                        </ListItem>
                                    ))}
                                </List>
                            )}
                            {index == 1 && extra != null && (
                                <Image filename={extra as string} alt={"specs"}/>
                            )}{" "}
                            {index == 2 && extra != null && (
                                <div style={{paddingLeft: "5%"}}>
                                    <BreadLink href={extra as string} target={"_blank"}>
                                        {extra as string}
                                    </BreadLink>
                                </div>
                            )}
                        </Accordion>
                    )
                ))}

            </div>
        )
    )
}

export default ExtraInfo
