import * as React from "react"

import Layout from "./Layout"
import Seo from "./seo"
import { Breadcrumbs, Button, Grid, Typography } from "@material-ui/core"
import { BreadLink, Capitalize, PageHeader } from "./partials"
import Image from "./Image"
import ProductSpecs from "./ProductSpecs"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import ExtraInfo from "./ExtraInfo"
import ReactPlayer from "react-player"
import { useCart } from "../useCart/useCart"
import { CartItem } from "../useCart/UseCartTypes"
import { withPrefix } from "gatsby"
import { Link, MenuItem, Select } from "@mui/material"
import { useState } from "react"
import { PageContext } from "gatsby/internal"

const MachinePage = ({ pageContext }: PageContext) => {
    const { product } = pageContext

    const { cart, handleAdd, handleRemove, handleQuantityChange } = useCart()

    const [selectedMac, setSelectedMac] = useState("")

    const handleChange = e => {
        setSelectedMac(e.target.value)
    }

    const addToCart = () => {
        if (product && product.model.options && selectedMac == "") {
            alert("Select a model first !")
        } else if (product) {
            const newItem: CartItem = {
                item: product,
                modelDetails: selectedMac,
                quantity: 1,
            }
            // @ts-ignore
            handleAdd(newItem)
        }
    }

    const breadComponent = product && (
        <Breadcrumbs aria-label="breadcrumb">
            <BreadLink underline="hover" href="/categories">
                Products
            </BreadLink>
            <BreadLink
                underline="hover"
                href={`/categories/${product.category.toLowerCase()}`}
            >
                {product.category}
            </BreadLink>
            <Typography>{product.model.title}</Typography>
        </Breadcrumbs>
    )

    console.log(product);

    return (
        product && (
            <Layout
                HeaderComponent={
                    <PageHeader
                        id={"productsHeader"}
                        header={
                            Capitalize(product.model.metadata.manufacturer) +
                            " " +
                            Capitalize(product.model.title)
                        }
                    />
                }
                BreadComponent={breadComponent}
                hasFeatured={true}
            >
                <Seo title={product.model.title} />
                <Grid item container xs={12} md={12} justify={"space-between"}>
                    <Grid item style={{ float: "left" }} xs={6}>
                        {product.model.metadata.siteLink && (
                            <Link
                                component={Button}
                                href={product.model.metadata.siteLink}
                                target={"_blank"}
                            >
                                Manufacturer Site
                            </Link>
                        )}
                    </Grid>
                    <Grid item container justify={"flex-end"} xs={6}>
                        <Grid item>
                            {product.model.metadata.options.length > 0 && (
                                <Select
                                    variant="filled"
                                    label={"Model"}
                                    onChange={handleChange}
                                    style={{ minWidth: "150px", marginRight: "15px" }}
                                >
                                    {product.model.metadata.options.map(option => (
                                        <MenuItem value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            )}
                            {cart && (
                                <Button
                                    color="secondary"
                                    variant={"contained"}
                                    onClick={() => addToCart()}
                                    startIcon={<ShoppingCartIcon />}
                                >
                                    Add To Cart
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid
                        item
                        container
                        xs={12}
                        md={6}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} md={12} style={{ height: "600px" }}>
                            <Image
                                alt={product.model.title}
                                filename={product.model.metadata.imageurl.url}
                            />
                        </Grid>
                    </Grid>
                    {product.model.metadata.youtube && (
                        <Grid
                            item
                            container
                            xs={12}
                            md={6}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item md={12} xs={12}>
                                <div className="player-wrapper">
                                    <ReactPlayer
                                        controls={true}
                                        className="react-player"
                                        url={product.model.metadata.youtube}
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <ProductSpecs product={product} />
                        <ExtraInfo product={product} />
                    </Grid>
                    {product.model.catalogue && product.model.catalogue != "" && (
                        <Grid item md={6} xs={12}>
                            <iframe
                                style={{ minHeight: "800px", width: "100%" }}
                                src={withPrefix(`/${product.model.catalogue}.pdf#view=fitH`)}
                            />
                        </Grid>
                    )}
                </Grid>
            </Layout>
        )
    )
}

export default MachinePage
