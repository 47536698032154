import * as React from "react"
import { Box, Grid, Typography, withStyles } from "@material-ui/core"
import { TextStyles } from "../styles/PartialStyles"
import { Product } from "../types/Types"

type Props = {
  product: Product
}

const ProductSpecs = ({ product }: Props) => (
  <Grid item>
    <Box p={4}>
      <Typography variant={"h4"}>{product.model.title}</Typography>
      <Typography variant={"body2"} gutterBottom>
        {product.model.metadata.manufacturer}
      </Typography>
      <Typography variant="body1" style={{ whiteSpace: "break-spaces" }}>
        {product.model.metadata.description}
      </Typography>
    </Box>
  </Grid>
)

export default ProductSpecs
